import React, { useState } from "react";
//----------- Import Bootstrap ---------------
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
//----------- Components ---------------------
import LayoutCustomer from "../../Hoc/LayoutCustomer";
//----------- Other imports -----------------
import classes from "./Rottamazioni.module.css";
import Smaltimento from "../../Assets/Images/2vie-samltimento.jpg";
import Compattatore from "../../Assets/Images/compattatore.jpg";
import IsolaBonifica from "../../Assets/Images/isola_bonifica.jpg";
import Macchinario from "../../Assets/Images/macchinario.jpg";
import LogoPdf from "../../Assets/Images/logo-pdf.gif";

const Rottamazioni = () => {
  const [docs, setDocs] = useState([
    {
      title: "ROTTAMAZIONE DI UN VEICOLO",
      documents: [
        { name: "certificato di proprietà o foglio complementare" },
        { name: "carta di circolazione" },
        {
          name: "In omaggio un buono carburante da 20€ presso il distributore IS di Via Lessini Terni",
        },
        { name: "visura" },
        {
          name: "documento intestatario e/o consegnatario-delegato (carta identità o patente di guida)",
        },
        {
          name: "se intestatario non presente per la firma, occorre delega con autorizzazione a fare la rottamazione per suo conto",
        },
      ],
      price: "Gratis",
      download: "http://www.grillofersnc.com/public/rottamazione.pdf",
    },
    {
      title: "ROTTAMAZIONE DI UN VEICOLO INTESTATO A PERSONA DECEDUTA",
      subTitle:
        "la rottamazione può essere effettuata SOLO da un erede diretto della persona deceduta",
      documents: [
        { name: "certificato di proprietà o foglio complementare" },
        { name: "visura" },
        {
          name: "documento erede diretto (carta identità o patente di guida)",
        },
      ],
      // price: "€ 100,00",
      price: "Gratis",
      download: "http://www.grillofersnc.com/public/deceduto.pdf",
    },
    {
      title: "ROTTAMAZIONE DI UN VEICOLO ROTTAMATO",
      subTitle:
        "la rottamazione può essere effettuata anche da una persona non intestataria del veicolo",
      documents: [
        { name: "certificato di radiazione o visura" },
        {
          name: "documento di identità valido (carta identità o patente di guida)",
        },
      ],
      // price: "€ 100,00",
      price: "Gratis",
      download: "http://www.grillofersnc.com/public/rottamato.pdf",
    },
    {
      title: "ROTTAMAZIONE DI UN VEICOLO AZIENDALE",
      documents: [
        { name: "certificato di proprietà o foglio complementare" },
        { name: "carta di circolazione" },
        { name: "visura" },
        {
          name: "documento rappresentante legale e/o consegnatario-delegato (carta identità o patente di guida)",
        },
        {
          name: "documento intestatario e/o consegnatario-delegato (carta identità o patente di guida)",
        },
        {
          name: "se rappresentante legale non presente per la firma, occorre delega con autorizzazione a fare la rottamazione per suo conto",
        },
        { name: "visura camerale dell'azienda" },
      ],
      // price: "€ 100,00",
      price: "Gratis",
      download: "http://www.grillofersnc.com/public/azienda.pdf",
    },
    {
      title: "ROTTAMAZIONE DI CICLOMOTORE E MOTOCICLO",
      subTitle: "",
      documents: [
        { name: "libretto di circolazione" },
        {
          name: "documento di identità valido (carta identità o patente di guida)",
        },
        {
          name: "certificato di proprietà ( solo motociclo )",
        },
      ],
      // price: "€ 30,00 + Spese trasporto: € 30,00 (nella provincia di Terni)",
      price: "€ 50,00",
      download: "http://www.grillofersnc.com/public/ciclomotore.pdf",
    },
  ]);
  return (
    <>
      <LayoutCustomer>
        <Container fluid>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <span className={classes.TitleSection}>Demolizioni</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <span>
                Qui puoi trovare il processo e la documentazione per la
                demolizione
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className={classes.Title}>Il processo</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.divBorder} />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <div style={{ display: "flex" }}>
                <span className={classes.stepNumber}>1.</span>
                <span style={{ paddingLeft: 10, paddingTop: 10 }}>
                  La prima operazione che viene effettuata è la rimozione delle
                  targhe del veicolo, al quale, nel frattempo viene assegnato un
                  numero seriale identificativo che ci aiuterà ad organizzare le
                  successive operazioni.
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <div style={{ display: "flex" }}>
                <span className={classes.stepNumber}>2.</span>
                <span style={{ paddingLeft: 10, paddingTop: 10 }}>
                  Una volta che il veicolo è stato identificato prenderà la
                  destinazione dell' "Isola di Bonifica", un'attrezzatura
                  designata appositamente per la rimozione di tutti i liquidi
                  presenti nel veicolo (olio motore, olio freni,antigelo del
                  radiatore, carburanti e liquido lavavetri). Dopo la rimozione
                  della batteria e del filtro dell'olio, qualora sia presente la
                  bombola del gas, un altro apposito macchinario procederà allo
                  svuotamento e pulizia di quest'ultima al fine di renderla
                  innocua.
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <div style={{ display: "flex" }}>
                <span className={classes.stepNumber}>3.</span>
                <div style={{ paddingTop: 10 }}>
                  <span style={{ paddingLeft: 10 }}>
                    A questo punto il veicolo messo in sicurezza potrà prendere
                    due strade:
                  </span>
                  <ul>
                    <li>
                      <span>
                        se è ancora presente un valore commerciale per la
                        vendita dei pezzi di ricambio, finirà negli appositi
                        scaffali di stoccaggio.
                      </span>
                    </li>
                    <li>
                      <span>
                        se il valore è nullo verrà immediatamente avviato alla
                        quarta fase di trattamento per la promozione del
                        riciclaggio delle varie componenti
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <div style={{ display: "flex" }}>
                <span className={classes.stepNumber}>4.</span>
                <span style={{ paddingLeft: 10, paddingTop: 10 }}>
                  Nella quarta ed ultima fase, la carcassa subisce lo smontaggio
                  dei materiali recuperabili di cui è composta: vetro,
                  plastiche, pneumatici etc.. La carcassa così "spolpata" si
                  dirigerà verso il compattatore, che la trasformerà in un cubo
                  metallico pronto per essere inviato agli impianti di
                  frantumazione che, con un ulteriore processo di separazione,
                  la trasformeranno in materia prima per le acciaierie, il quale
                  prodotto tornerà ad essere di nuovo parte di un veicolo!
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col>
              <Image style={{ width: "100%" }} src={Macchinario} />
            </Col>
            <Col>
              <Image style={{ width: "100%" }} src={IsolaBonifica} />
            </Col>
            <Col>
              <Image style={{ width: "100%" }} src={Smaltimento} />
            </Col>
            <Col>
              <Image style={{ width: "100%" }} src={Compattatore} />
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col>
              <span className={classes.Title}>I Documenti</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.divBorder} />
            </Col>
          </Row>
          <Row>
            <Col>
              <span>
                Per vostra comodità qui troverete la lista dei documenti
                necessari per la rottamazione di un veicolo nei vari casi. è
                possibile scaricare il PDF relativo cliccando sull'icona a
                destra della descrizione
              </span>
            </Col>
          </Row>
          {docs.map((doc) => {
            return (
              <>
                <Row style={{ marginTop: "1rem" }}>
                  <Col className="text-center">
                    <span className={classes.subTitle}>{doc.title}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <span>{doc.subTitle}</span>
                  </Col>
                </Row>
                <Card>
                  <Row style={{ marginTop: 20 }}>
                    <Col xs={10}>
                      <ul>
                        {doc.documents.map((document) => {
                          return (
                            <li>
                              <span>{document.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                    <Col className="text-center my-auto">
                      <a href={doc.download} target="_blank">
                        <Image style={{ width: 100 }} src={LogoPdf} />
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 30 }}>
                      <Row>
                        <Col>
                          <span className={classes.subTitle}>
                            COSTO: {doc.price}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span style={{ fontSize: 12 }}>
                            *in mancanza dei documenti originale effettuare la
                            denuncia di smarrimento
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          })}
        </Container>
      </LayoutCustomer>
    </>
  );
};

export default Rottamazioni;
