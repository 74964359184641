import React from "react";
//-------------- Boostrap ------------------
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import BlackCar from "../../Assets/Images/blackcar.png";
import { Link } from "react-router-dom";

const BannerSellingCar = () => {
  return (
    <>
      <Row
        style={{ backgroundColor: "#ffd54c", padding: 20, marginTop: "2rem" }}
      >
        <Col className="my-auto" xs={12} lg={6}>
          <span style={{ fontSize: 25, fontWeight: "bold" }}>
            Valutiamo la tua auto incidentata o non funzionante di recente
            immatricolazione
          </span>
          {/*<p>*Riferito a veicoli di recente immatricolazione</p>*/}
        </Col>
        {/* <Col className='my-auto'>
                <Link to='/vendi-auto'>
                <Button style={{width: 200}} className='otherButton'>
                    <span className='otherButtonText'>Contattaci</span>
                </Button>
                </Link>
            </Col> */}
        <Col xs={12} lg={3} className="my-auto">
          <Image src={BlackCar} style={{ width: "100%" }} />
        </Col>
      </Row>
    </>
  );
};

export default BannerSellingCar;
