import React, { useEffect } from 'react';
import './App.css';
//------------------------- Component Import ----------------------------------
import LayoutGeneral from '../Hoc/LayoutGeneral';
import LayoutAdmin from '../Hoc/LayoutAdmin';
import MainPage from '../Containers/MainPage/MainPage';
//--------------------------  Route Import -----------------------------------
import {Redirect, Route, Router as BrowserRouter, Switch, useHistory} from 'react-router-dom';
import Rottamazioni from './Rottamazioni/Rottamazioni';
import ServicePage from './ServicePage/ServicePage';
import CarListPage from './CarListPage/CarListPage';
import AboutUs from './AbousUs/AboutUs';
import DetailCar from './DetailCar/DetailCar';
import SellingCar from './SellingCar/SellingCar';
import Login from './Login/Login';
import ContactListPage from './AdminManager/ContactListPage/ContactListPage';
import AdminCars from './AdminManager/CarListPage/CarListPage';

// this is kept in sync by actions signIn and signOut
const isAuthenticated = () => localStorage.getItem('token');
const PublicRoute = props => <Route {...props} />;
const PrivateRoute = (props) => isAuthenticated() ? <Route {...props} /> : <Redirect to={'/'}/>;


const App = () => {
  const history = useHistory();

  //------------------------------------ Rendering ----------------------------------------------
    return (
      <div className= 'App'>
        <BrowserRouter history={history} basename={'/'}>
        <Switch>
          <PublicRoute path="/" exact render={() => <LayoutGeneral page={'home'}><MainPage /></LayoutGeneral>}  />
          <PublicRoute path="/login" exact render={() => <LayoutGeneral page={'login'}><Login /></LayoutGeneral>}  />
          <PublicRoute path="/rottamazioni" exact render={() => <LayoutGeneral page={'rottamazioni'}><Rottamazioni /></LayoutGeneral>}  />
          <PublicRoute path="/servizi" exact render={() => <LayoutGeneral page={'servizi'}><ServicePage /></LayoutGeneral>}  />
          <PublicRoute path="/ricambi" exact render={() => <LayoutGeneral page={'ricambi'}><CarListPage /></LayoutGeneral>}  />
          <PublicRoute path="/su-di-noi" exact render={() => <LayoutGeneral page={'ricambi'}><AboutUs /></LayoutGeneral>}  />
          <PublicRoute path="/dettagli/:id" exact render={() => <LayoutGeneral page={'dettagli'}><DetailCar /></LayoutGeneral>}  />
          <PublicRoute path="/vendi-auto" exact render={() => <LayoutGeneral page={'dettagli'}><SellingCar /></LayoutGeneral>}  />
          <PrivateRoute path="/admin-contatti" exact render={() => <LayoutGeneral ><LayoutAdmin ><ContactListPage /></LayoutAdmin></LayoutGeneral>}  />
          <PrivateRoute path="/admin-cars" exact render={() => <LayoutGeneral ><LayoutAdmin ><AdminCars /></LayoutAdmin></LayoutGeneral>}  />
        </Switch>
        </BrowserRouter>
      </div>
    );
  }


export default App;
