import React, {useEffect, useState} from 'react'
//------------------ Bootstrap import --------------------------
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";
import {Link, useHistory} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
//import { hotjar } from 'react-hotjar';
import { useDispatch, useSelector} from 'react-redux';
import * as actionCreator from '../store/action/index';
//hotjar.initialize('1808374');

ReactGA.initialize('UA-174280229-1');
const trackPage = (page) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};



const LayoutGeneral = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector((state) => state.user.userData);
    useEffect(() => {
        if(!Object.keys(userData).length && localStorage.getItem('token')){
            dispatch(actionCreator.storeUserData());
        }
    },[]);

    return(
        <>
        {trackPage(props.page ? props.page : 'Another Page')}
            <>
                {props.children}
                <CookieConsent
                    buttonText="Accetto"
                >
                    Il sito utilizza i cookie, accettando o proseguendo nel sito accetti la <Link to='/cookie-policy'>cookie privacy policy</Link>
                </CookieConsent>
            </>     
        </>
    );
}

export default LayoutGeneral;
