import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-------------- Components ----------------
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import * as authApi from '../../../Axios/Call/AuthApi';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import ImageUploader from 'react-images-upload';

const ModalAddCar = (props) => {
    const [pictures, setPictures] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(-1);
    const [errorApi , setErrorApi ] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [car, setCar] = useState({});

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
        defaultValues:{}
    }); // initialise the hook

    const onClickCreateCar = (data) => {
        
        setLoadingApi(true);
        if(props.openModal < 1){
            authApi.createCar({...data, pictures: pictures}).then(response => {
                setLoadingApi(false);
                props.setOpenModal(false);
                props.setCarAdded(!props.carAdded)
            }).catch(error => {
                setLoadingApi(false);
                setErrorApi(true);
            })
        }else{
            authApi.editCar(props.openModal,{...data, pictures: pictures}).then(response => {
                setLoadingApi(false);
                props.setOpenModal(false);
                props.setCarAdded(!props.carAdded)
            }).catch(error => {
                setLoadingApi(false);
                setErrorApi(true);
            })
        }
    }

    useEffect(() => {
        if(props.openModal > 0){
            authApi.showCar(props.openModal).then(response => {
                setCar(response.data.data);
            }).catch(error => {
                console.log(error)
            })
        }
    },[props.openModal])

    useEffect(() => {
        if(props.openModal > 0 && Object.keys(car).length){
            reset(car);
        }
    },[car])

    useEffect(() => {
        if(files.length && files.length > currentFile){
            const file = files[currentFile];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                console.log(currentFile)
                setPictures(pictures.concat(reader.result));
                setCurrentFile(currentFile + 1);
            };
        }
    },[currentFile]);

    const uploadPictureRequest = (picture) => {
        setFiles(files.concat(picture));
        setCurrentFile(0);
    }

    return(<>
        <Modal show={true}  onHide={() => props.setOpenModal(false)} size='xl'>
        {errorApi ? <AlertGeneral closeAlone setTrigger={setErrorApi} description='errore upload' color='danger' /> : null}
                <Modal.Header closeButton>
                    <Row>
                        <Col className='text-center'>
                            <span>Aggiungi una macchina</span>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body >
                <Form onSubmit={handleSubmit(onClickCreateCar)} >
                        <Row>
                            <Col xs={12} lg={6}>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'name'}
                                    error={ errors.name ?  'Campo richiesto' : ''} inputStyle={{width: '80%'}} offset={0} span={12} labelName={'Name'}
                                    labelStyle={{textTransform: 'uppercase', color: 'black'}}
                                />
                            </Col>
                            <Col>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'model'}
                                    error={ errors.model ?  'Campo richiesto' : ''} inputStyle={{width: '80%'}} offset={0} span={12} labelName={'Modello'}
                                    labelStyle={{textTransform: 'uppercase', color: 'black'}}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row><Col>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'year'}
                                    error={ errors.year ?  'campo richiesto' : ''} inputStyle={{width: props.uploadPicture ? '100%' :'60%'}} 
                                    labelStyle={{textTransform: 'uppercase', marginTop: 20, color: 'black'}}
                                    offset={0} span={12} labelName={'Anno'}
                                />
                                </Col></Row>
                                <Row><Col>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'brand'}
                                    error={ errors.brand ? 'campo richiesto' : ''} inputStyle={{width: props.uploadPicture ? '100%' :'60%'}} 
                                    labelStyle={{textTransform: 'uppercase', marginTop: 20, color: 'black'}}
                                    offset={0} span={12} labelName={'Brand'}
                                />
                                </Col></Row>
                            </Col>
                            <Col style={{marginTop: 20}}>
                                <ImageUploader
                                    withIcon={false}
                                    buttonText="Scegli foto"
                                    onChange={(event) => uploadPictureRequest(event)}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                    fileTypeError={'File non supportato'}
                                    fileSizeError={'File troppo grande'}
                                    maxFileSize={5242880}
                                    label="Max file:5Mb, formati: .jpg | .gif | .png | .gif | .jpeg"
                                    singleImage={false}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: '2.5rem', paddingLeft: 20}}><Col className='text-center'>
                            <Button type='submit' className='mainButton' style={{width: 100}}><span className='mainButtonText'>Invia</span></Button>
                        </Col></Row>
                    </Form>
                </Modal.Body>
            </Modal>
    </>)
}


export default ModalAddCar;