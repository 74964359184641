import React, {useState} from 'react';
//-------------- Boostrap ------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-------------- Components ----------------
import TextBox from '../../Components/Commons/FormComponent/TextBox';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import {Grid} from '../../icons';
import ImageUploader from 'react-images-upload';
//-------------- Style ---------------------
import classes from './FormContact.module.css';

const FormContact = (props) => {

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    return(<>
        <Row style={{marginTop: '2rem'}}>
            <Col xs={12} lg={props.noSide ? 2 : 4}>
            {props.noSide ? null : <>
                <p className={classes.textHeader}>PER ALTRE INFORMAZIONI O RICHIESTE</p>
                <span className={classes.textSubHeader}>Compila questo breve format ti scriveremo appena è disponibile</span>
                </>
            }
            </Col>
            
            <Col xs={12} lg={8}>
                <Card style={{backgroundColor: '#0D2E3D', padding: 30, zIndex: 10 }} >
                    <Form onSubmit={handleSubmit(props.onClickContactHandler)} >
                        <Row>
                            <Col xs={12} lg={6}>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'name'}
                                    error={ errors.name ?  'Campo richiesto' : ''} inputStyle={{width: '80%'}} offset={0} span={12} labelName={'Name'}
                                    labelStyle={{textTransform: 'uppercase'}}
                                />
                            </Col>
                            <Col>
                                <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'surname'}
                                    error={ errors.surname ?  'Campo richiesto' : ''} inputStyle={{width: '80%'}} offset={0} span={12} labelName={'Cognome'}
                                    labelStyle={{textTransform: 'uppercase'}}
                                />
                            </Col>
                        </Row>
                        <Row><Col>
                        <TextBox ref={register({ validate: emailValidator })} placeHolder={'Scrivi qui'} type={'email'} name={'email'}
                            error={ errors.email ?  errors.email.message : ''} inputStyle={{width: props.uploadPicture ? '100%' :'60%'}} 
                            labelStyle={{textTransform: 'uppercase', marginTop: 20}}
                            offset={0} span={12} labelName={'Email'}
                        />
                        </Col>
                        { props.uploadPicture ?
                            <Col style={{marginTop: 20}}>
                                <ImageUploader
                                    withIcon={false}
                                    buttonText="Scegli foto"
                                    onChange={(event) => props.uploadPictureRequest(event)}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                    fileTypeError={'File non supportato'}
                                    fileSizeError={'File troppo grande'}
                                    maxFileSize={5242880}
                                    label="Max file:5Mb, formati: .jpg | .gif | .png | .gif | .jpeg"
                                    singleImage={false}
                                />
                            </Col>
                        : null}
                        </Row>
                        <TextBox ref={register({ required: 'true' })} placeHolder={'Scrivi qui'} type={'input'} name={'question'}
                            error={ errors.comments ?  'Campo richiesto' : ''} inputStyle={{width: '100%'}} 
                            labelStyle={{textTransform: 'uppercase', marginTop: 20}}
                            offset={0} span={12} labelName={'Richiesta'} textarea
                        />
                        <Row style={{marginTop: '2.5rem', paddingLeft: 20}}><Col>
                            <Button type='submit' className='mainButton' style={{width: 100}}><span className='mainButtonText'>Invia</span></Button>
                        </Col></Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    </>)
}

export default FormContact;