import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Containers/App';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
//-----------------Redux Import ------------------------------
import {Provider} from 'react-redux';
import {createStore, combineReducers, applyMiddleware, compose } from 'redux';
import userReducer from './store/reducer/user';
import thunk from 'redux-thunk';
//-------------------------------------------------------------
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_8FZ7V0iJo9aXTLdc5j6PdeUO00xcwjqsjk');
//pk_test_8FZ7V0iJo9aXTLdc5j6PdeUO00xcwjqsjk


const rootReducer = combineReducers({
  user: userReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(

  <Elements stripe={stripePromise}>
  <Provider store={store}>
    <BrowserRouter >
        <App />
      </BrowserRouter>
  </Provider>
  </Elements>
      , document.getElementById('root'));
  serviceWorker.unregister();
