import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../Assets/Images/logogrillo.png";
import LogoMobile from "../Assets/Images/logogrillo.png";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classes from "./Layout.module.css";
import Button from "react-bootstrap/Button";
import * as actionCreator from "../store/action/index";
import { useWindowWidth } from "@react-hook/window-size";
import { FacebookIcon } from "../icons";

const LayoutCustomer = (props) => {
  const userData = useSelector((state) => state.user.userData);
  const screenWidth = useWindowWidth();
  const dispatch = useDispatch();

  let menuButton = null;
  if (Object.keys(userData).length) {
    menuButton = (
      <Nav.Link
        as={Link}
        to="/admin-contatti"
        className={"navItem " + classes.marginRight}
        eventKey="Cart"
      >
        <span className={classes.nameStyle}>Amministrazione</span>
      </Nav.Link>
    );
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="xl"
        variant="light"
        style={{ backgroundColor: "#FEE302" }}
      >
        <Navbar.Brand as={Link} to="/">
          <Image
            style={{ width: screenWidth > 720 ? "60%" : "50%" }}
            src={screenWidth > 720 ? Logo : LogoMobile}
            alt="grillofer-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            {/* <Nav.Link
              as={Link}
              to="/ricambi"
              className={"navItem " + classes.marginRight}
              eventKey="ricambi"
            >
              <span className={classes.nameStyle}>Ricambi</span>
  </Nav.Link> */}
            <Nav.Link
              as={Link}
              to="/servizi"
              className={"navItem " + classes.marginRight}
              eventKey="servizi"
            >
              <span className={classes.nameStyle}>Servizi</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/su-di-noi"
              className={"navItem " + classes.marginRight}
              eventKey="su-di-noi"
            >
              <span className={classes.nameStyle}>Su di noi</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/rottamazioni"
              className={"navItem " + classes.marginRight}
              eventKey="su-di-noi"
            >
              <span className={classes.nameStyle}>Rottamazioni</span>
            </Nav.Link>
            <Nav.Link
              className={"navItem " + classes.marginRight}
              eventKey="su-di-noi"
            >
              <span className={classes.nameStyle}>Agenzia pratiche auto</span>
            </Nav.Link>
            {menuButton}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {props.children}
      <div style={{ width: "100%" }}>
        <Col>
          <Row style={{ marginTop: "2rem", backgroundColor: "#ffd54c" }}>
            <Col style={{ padding: 40 }}>
              <Row>
                <Col>
                  <span className={classes.nameStyle}>Grillofer s.r.l</span>
                  <FacebookIcon style={{ width: 40, paddingLeft: 10 }} />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <span className={classes.textFooter}>
                    Via Curio Fornace 5/7,Maratta Bassa - Terni
                  </span>
                </Col>
                <Col>
                  <span className={classes.textFooter}>P.I.00476870555</span>
                </Col>
                <Col>
                  <span className={classes.textFooter}>Tel. 0744.300755</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <span className={classes.textFooter}>Fax. 0744.307196</span>
                </Col>
                <Col>
                  <span className={classes.textFooter}>
                    Whatsapp ricambi 338.9327324
                  </span>
                </Col>
                <Col>
                  <span className={classes.textFooter}>
                    e-mail info@grillofersnc.com
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};

export default LayoutCustomer;
