import React, { useEffect } from 'react';
//---------- React bootstrap -----------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
//---------- Other import ---------------
import { ImageIcon, PostIcon, SeeIcon} from '../../icons';
import {useHistory} from 'react-router-dom';

const ListCars = (props) => {
    const history = useHistory();

    const onClickDetailHandler = (id) => {
        history.push(`/dettagli/${id}`);
    }

    return(
        <>
            <Row style={{marginTop: '2rem'}}>
                <Col>
                    <Card>
                        <Row>
                            <Col xs={12} lg={3}>
                                <Image src={props.car.picture} style={{width: '100%'}}/>
                            </Col>
                            <Col style={{padding: 20}}>
                                <Row>
                                    <Col><p style={{fontSize: 18, fontWeight: 'bold'}}>{props.car.name}</p></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ImageIcon style={{ width: 20, fill: 'grey'}}/>
                                        <span style={{paddingLeft: 10, fontSize: 13}}>{props.car.year}</span>
                                    </Col>
                                    <Col>
                                        <PostIcon style={{ width: 20, fill: 'grey'}}/>
                                        <span style={{paddingLeft: 10, fontSize: 13 }}>{props.car.model}</span>
                                    </Col>
                                    <Col>
                                        <SeeIcon style={{ width: 20,fill: 'grey'}}/>
                                        <span style={{paddingLeft: 10, fontSize: 13 }}>{props.car.brand}</span>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: 30}}><Col>
                                    <Button className='mainButton' onClick={() => onClickDetailHandler(props.car.id)}>
                                        <span className='mainButtonText'>Dettagli</span>
                                    </Button>
                                </Col></Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ListCars;