import axios from 'axios';

export const clientCredentialAxios = axios.create({
    baseURL:axios.defaults.baseURL = 'https://apigrillofer.manciomarket.com/api/'
    //'https://apigrillofer.manciomarket.com/api/'
    //http://127.0.0.1:8000/api/
});

clientCredentialAxios.defaults.headers.common['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNWQ3ODdjOTAwMjVjZmFhYjRmNDcwODA4N2IxMWQ1ZDZmZjdiNWJlODk0NTgzODA4NDJmZmYxOWI0N2JjNmNkMGEwZGQ1OTY5ODgxNmM3MTUiLCJpYXQiOjE1ODYxMDE4NDcsIm5iZiI6MTU4NjEwMTg0NywiZXhwIjoxNjE3NjM3ODQ3LCJzdWIiOiIiLCJzY29wZXMiOltdfQ.mruaoPV6N25yO3xbutS4D_u7XWEoZX3on-0Qt1dMJyjSeHelAVaddBBF2YiYqGZl6FmdlfSkMWOBMeO_bYVr_sQyQWz4b_Z3memHFm9L8Ota-ZrbVRc1SS53PBcVMzauPbAuII2T_Dr1efIqe_yVr2SVyAuLfJ69GDAkHDBI9nASBi666tyKL_ar4AgmPbVEmPFaD8OcDVJwLCbgaOUQdXeO-eueFqhOXb1HmnBtaogZ-QzEVAW67f7T42r2TsB1o_qHkb7RaP8M_YAwRr8M9OCGlfSHu8ugs0RKpA3vYY3dS0knchSi-JpOimxBhH-Iy4hezrOY8Uk2eNOu-fKgP6hXmBxCEhktJQxR8rxprsxit3mnHRJJwc8ph3Q1Mb1y-rGF06p0AZtWOpd3bQE2OR0MEHVzDHgHzQNetUgyl1o3b-RIqXbSLA6cPxY3066w0fVccX0hj1K804u2vTs4ONp5AqfSxrXhbFaHcuoUjoAiqooOOgwmKNP2-x0XUB9RksuJLJaw5-q2Dtx4HuvCs2NXStgxHrKMW5r41xRjLEybgj8uu9JB8dn1gUK_wLJiDAoTmo9zRdRdlVW1RGWJaZgE3uwV451Kp6CMWBl1jk3c4f__D3HeYUWulcJEdUPTcPlexAO3HU5lAz2pYML3HMp1tAgOYkuRBacr2CZtbEk'

export const authApiAxios = axios.create({
    baseURL:axios.defaults.baseURL = 'https://apigrillofer.manciomarket.com/api/'
    //'https://apiworkoutly.manciomarket.com/api'
    //http://127.0.0.1:8000/api/
})

authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
