import {authApiAxios} from '../AxiosInstance';

//------------------------------ User Api Call -------------------
export const getUserData = () => {
    return authApiAxios.get('me');
}

export const getContacts = () => {
    return authApiAxios.get('admin/contacts');
}

export const getCars = () => {
    return authApiAxios.get('admin/cars');
}

export const deletePicture = (id) => {
    return authApiAxios.delete(`admin/car/picture/${id}`);
}

export const changeStatus = (id) => {
    return authApiAxios.get(`admin/car/${id}/status-change`);
}

export const createCar = (data) => {
    return authApiAxios.post(`admin/car`,data);
}

export const editCar = (id,data) => {
    return authApiAxios.post(`admin/car/${id}`,data);
}

export const showCar = (id) => {
    return authApiAxios.get(`admin/car/${id}`);
}