import React, { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
//-------------- Slider Import ---------------------
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//-------------- React Bootstrap -------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
//----------- Components ------------
import LayoutCustomer from '../../Hoc/LayoutCustomer';
import FormContact from '../../Components/MainPage/FormContant';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
//----------- Other import ----------
import classes from './DetailCar.module.css';
import { ImageIcon, PostIcon, SeeIcon} from '../../icons';
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';
import { useWindowWidth } from '@react-hook/window-size'; 

const DetailCar = () => {
    const {id} = useParams();
    const [car, setCar ] = useState({ gallery:[] });
        //---------- Contact ----------
        const [contractErrorApi, setContractErrorApi ] = useState(false);
        const [contractLoadingApi, setContractLoadingApi] = useState(false);
        const [contractSuccessApi, setContractSuccessApi] = useState(false);
        const screenWidth = useWindowWidth();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(id){
            clientCredentailApi.oneCar(id).then(response => {
                setCar(response.data.data);
            }).catch(error => {
                console.log(error);
            })
        }
    },[id])

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const onClickContactHandler = (data) => {
        setContractLoadingApi(true);
        clientCredentailApi.sendContact({...data, type:'part', car_id: id}).then(response => {
            setContractLoadingApi(false);
            setContractSuccessApi(true);
        }).catch(error => {
            setContractLoadingApi(false);
            setContractErrorApi(true);
        })
    }

    return( <> 
     <LayoutCustomer >
            <Container fluid>
            {contractSuccessApi ? <AlertGeneral setTrigger={setContractSuccessApi} closeAlone description='Form inviato con successo' color='success' /> : null}
            {contractErrorApi ? <AlertGeneral setTrigger={setContractErrorApi} closeAlone description='Errore nel form' color='danger' /> : null}
                <Row style={{marginTop: '2rem'}}>
                    <Col className='text-center'><span className={classes.titleStyle}>{car.name}</span></Col>
                </Row>
                <Row style={{ marginTop: '2rem'}}>
                    <Col className='text-center'>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={1500}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            >
                                {car.gallery.map(picture => {
                                    return(
                                        <Image src={picture.picture} style={{width:screenWidth > 800 ?'30%' : '100%' }}/>
                                    )
                                })}
                            </Carousel>
                    </Col>
                </Row>
                <Row style={{marginTop: '1rem'}}>
                    <Col className='text-center'>
                        <ImageIcon style={{ width: 20, fill: 'grey'}}/>
                        <span style={{paddingLeft: 10, fontSize: 13}}>{car.year}</span>
                    </Col>
                    <Col className='text-center'>
                        <PostIcon style={{ width: 20, fill: 'grey'}}/>
                        <span style={{paddingLeft: 10, fontSize: 13 }}>{car.model}</span>
                    </Col>
                    <Col className='text-center'>
                        <SeeIcon style={{ width: 20,fill: 'grey'}}/>
                        <span style={{paddingLeft: 10, fontSize: 13 }}>{car.brand}</span>
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col className='text-center'><span>Per ulteriori informazioni sui pezzi di ricambio disponilibi puo' contattarci tramite il seguente form.</span></Col>
                </Row>
                <FormContact noSide onClickContactHandler={onClickContactHandler}/>
            </Container>
        </LayoutCustomer>
    </> )
}

export default DetailCar;