import React, { useEffect } from "react";
//----------- Bootstrap --------------
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
//----------- Components ------------
import LayoutCustomer from "../../Hoc/LayoutCustomer";
//----------- Other import -------------
import SoccorsoPic from "../../Assets/Images/depositera.jpg";
import Raccolta from "../../Assets/Images/raccolta.jpg";
import Depositeria from "../../Assets/Images/deposito.jpg";
import classes from "./ServicePage.module.css";

const ServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <LayoutCustomer>
        <Container fluid>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <span className={classes.TitleSection}>Servizi</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <span>Scopri i servizi che la Grillofer srl ha da offrire</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col xs={12} md={8} className="my-auto">
              <Row>
                <Col className="text-center">
                  <p className={classes.Title}>Soccorso Stradale</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>
                    La depositeria giudiziaria occupa un’area di circa 7000 mq.
                    completamente recintata e dotata di illuminazione e custode
                    per le ore notturne. Oltre ai normali interventi di soccorso
                    stradale, rimozione e affidamento in custodia, con l’entrata
                    in vigore del Decreto Ministeriale 22.10.1999 n.460, la
                    società ritira su richiesta degli organi competenti i
                    veicoli in stato di abbandono rinvenuti in aree pubbliche.
                    Tutti i giorni 24h su 24 il soccorso stradale Grillofer è a
                    vostra disposizione
                  </span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Image src={SoccorsoPic} style={{ width: "100%" }} />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xs={12} md={3}>
              <Image src={Raccolta} style={{ width: "100%" }} />
            </Col>
            <Col className="my-auto">
              <Row>
                <Col className="text-center">
                  <p className={classes.Title}>
                    Raccolta e smaltimento rifiuti
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>
                    Per soddisfare le esigenze di officine meccaniche,
                    carrozzerie ed impianti siderurgici la società è in grado di
                    ritirare i materiali di scarto risultanti dalle varie
                    attività lavorative con i propri mezzi autorizzati fornendo
                    anche il servizio di noleggio di cassoni scarrabili di varie
                    misure a seconda dei quantitativi prodotti o degli spazi a
                    disposizione degli utenti.
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xs={12} md={8} className="my-auto">
              <Row>
                <Col className="text-center">
                  <p className={classes.Title}>Deposito giudiziario</p>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </span>
                </Col>
            </Row> */}
            </Col>
            <Col>
              <Image src={Depositeria} style={{ width: "100%" }} />
            </Col>
          </Row>
        </Container>
      </LayoutCustomer>
    </>
  );
};

export default ServicePage;
