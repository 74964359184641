import * as actionsTypes from './actionsTypes';
import * as authApi from '../../Axios/Call/AuthApi';
import {authApiAxios} from '../../Axios/AxiosInstance';


export const storeUserData = () => {
    return(dispatch,state) => {
        authApi.getUserData().then(response => {
            const userData = response.data.data
            dispatch(saveUserData(userData));
        })
    }
}

const saveUserData = (userData) => {
    return{
        type: actionsTypes.STORE_USER_DATA,
        userData: userData
    }
}

export const logoutHandler = () => {
    localStorage.removeItem("token");
    return{
        type: actionsTypes.LOGOUT_USER
    }
}