import React, { useState, useEffect } from "react";
//----------- Import Bootstrap ---------------
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
//------------ Components ----------------------
import FormContact from "../../Components/MainPage/FormContant";
import AlertGeneral from "../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../Components/Commons/LoadingButton/LoadingButton";
import LayoutCustomer from "../../Hoc/LayoutCustomer";
import ListCars from "../../Components/ListCar/ListCars";
import BannerSellingCar from "../../Components/MainPage/BannerSellingCar";
//-------------- Other imports --------------------
import * as clientCredentailApi from "../../Axios/Call/ClientCredential";
import { useHistory, Link } from "react-router-dom";
import Logo from "../../Assets/Images/copertina.jpg";
import classes from "./MainPage.module.css";
import { Heands, Contract, Price, Time } from "../../icons";
import WhatsAppIcon from "../../Assets/Images/whatsapp.png";
import Banner from "../../Assets/Images/Banner.png";

const MainPage = () => {
  const [icons, setIcons] = useState([
    { icon: "heands", title: "Rottamazioni", link: "rottamazioni" },
    { icon: "contract", title: "Smaltimento rifiuti", link: "servizi" },
    { icon: "price", title: "Deposito giudiziario", link: "servizi" },
    { icon: "time", title: "Trasporti", link: "servizi" },
  ]);
  //---------- Contact ----------
  const [contractErrorApi, setContractErrorApi] = useState(false);
  const [contractLoadingApi, setContractLoadingApi] = useState(false);
  const [contractSuccessApi, setContractSuccessApi] = useState(false);
  //-----------------------------
  const [cars, setCars] = useState([]);
  const history = useHistory();

  useEffect(() => {
    clientCredentailApi
      .getCars()
      .then((response) => {
        setCars(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo(0, 0);
  }, []);

  const renderIcon = (type) => {
    switch (type) {
      case "contract":
        return <Contract />;
      case "heands":
        return <Heands />;
      case "price":
        return <Price />;
      case "time":
        return <Time />;
    }
  };

  const onClickContactHandler = (data) => {
    setContractLoadingApi(true);
    clientCredentailApi
      .sendContact({ ...data, type: "general" })
      .then((response) => {
        setContractLoadingApi(false);
        setContractSuccessApi(true);
      })
      .catch((error) => {
        setContractLoadingApi(false);
        setContractErrorApi(true);
      });
  };

  const onClickShopHandler = () => {
    history.push("/ricambi");
  };

  return (
    <>
      <LayoutCustomer>
        <Row
          style={{
            position: "fixed",
            zIndex: 99,
            top: "88%",
            transform: "translateY(-88%)",
          }}
        >
          <Col
            style={{
              width: 100,
              backgroundColor: "white",
              borderRadius: "90%",
              paddingLeft: 28,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Row>
              <Col style={{ position: "relative" }}>
                <a
                  href="https://api.whatsapp.com/send?phone=+393389327324"
                  target="_blank"
                >
                  <Image src={WhatsAppIcon} className={classes.whatsapp} />
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ fontSize: 12, color: "black" }}>
                  Whatsapp Ricambi
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        {contractSuccessApi ? (
          <AlertGeneral
            setTrigger={setContractSuccessApi}
            closeAlone
            description="Form inviato con successo"
            color="success"
          />
        ) : null}
        {contractErrorApi ? (
          <AlertGeneral
            setTrigger={setContractErrorApi}
            closeAlone
            description="Errore nel form"
            color="danger"
          />
        ) : null}
        <div style={{ position: "relative" }}>
          <div
            style={{
              width: "100%",
              //opacity: "0.5"
            }}
          >
            {
              // <Image style={{ width: "100%", height: "400px" }} src={Logo} />
            }
            <Image style={{ width: "100%", height: "600px" }} src={Banner} />
          </div>
          {/* <Row style={{ position: "absolute", top: "25%", left: "5%" }}>
            <Col>
              <Row>
                <Col className="text-left">
                  <span className={classes.coverTitle}>Grillofer s.r.l</span>
                </Col>
              </Row>
              <Row>
                <Col className="text-left" style={{ width: 350 }}>
                  <span>
                    Centro raccolta all'avanguardia nel network istituito dalle
                    maggiori case automobilistiche
                  </span>
                </Col>
              </Row>
              {/*  <Row style={{ marginTop: 8 }}>
                <Col className="text-left">
                  <Button
                    style={{ width: 100 }}
                    className="mainButton"
                    onClick={() => onClickShopHandler()}
                  >
                    <span className="mainButtonText">Shop</span>
                  </Button>
                </Col>
              </Row>
              
            </Col>
          </Row>
          */}
        </div>
        <Container fluid>
          <BannerSellingCar />
          <Row style={{ marginTop: "2rem" }}>
            {icons.map((icon) => {
              return (
                <Col>
                  <Card style={{ padding: 20 }}>
                    <Row>
                      <Col xs={4}>{renderIcon(icon.icon)}</Col>
                      <Col className="my-auto">
                        <Row>
                          <Col className="text-left">
                            <Link to={"/" + icon.link}>
                              <span>{icon.title}</span>
                            </Link>
                          </Col>
                        </Row>
                        {/*<Row><Col className='text-left'><span style={{fontSize: 11}}>{icon.description}</span></Col></Row>*/}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <span style={{ fontSize: 13 }}>
                La Grillofer S.N.C nasce all' inizio degli anni '80, ed ha come
                attivita' principale e missione aziendale, la gestione dei
                cosidetti ELVs ( End Life Veichles ) o meglio, dei veicoli
                giunti ormai al termine del loro ciclo vitale. Nel corso dei
                decenni, la nostra azienda, tramite investimenti mirati e grazie
                sopratutto alla predisposizione al lungo-termine, e' riuscita ad
                elevarsi come eccellenza europea in questo determinato settore.,
                con un occhio sempre orientato ai massimi principi di
                responsabilita' sociale, quali il rispetto e la tutela
                dell'ambiente, il rispetto dei collaboratori ed il rispetto dei
                fornitori e clienti.{" "}
              </span>
            </Col>
          </Row>
          {/* <Image style={{ width: "100%", height: "600px" }} src={Banner} /> */}
          {/* cars.splice(0, 3).map((car) => {
            return <ListCars key={car.id} car={car} />;
          }) 
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <Button
                style={{ width: 250 }}
                className="mainButton"
                onClick={() => onClickShopHandler()}
              >
                <span className="mainButtonText">Più Auto</span>
              </Button>
            </Col>
          </Row>
          */}
          {/* <FormContact onClickContactHandler={onClickContactHandler} /> */}
        </Container>
      </LayoutCustomer>
    </>
  );
};

export default MainPage;
