import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
//-------------- React Bootstrap -------------------
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import AlertGeneral from '../../Components/Commons/AlertGeneral/AlertGeneral';
//----------- Components ------------
import LayoutCustomer from '../../Hoc/LayoutCustomer';
import FormContact from '../../Components/MainPage/FormContant';
//----------- Other import ----------
import classes from './SellingCar.module.css';
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';

const DetailCar = () => {
    const [pictures, setPictures] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(0);
     //---------- Contact ----------
     const [contractErrorApi, setContractErrorApi ] = useState(false);
     const [contractLoadingApi, setContractLoadingApi] = useState(false);
     const [contractSuccessApi, setContractSuccessApi] = useState(false);
    
     const onClickContactHandler = (data) => {
        setContractLoadingApi(true);
        clientCredentailApi.sendContact({...data, type:'car', pictures: pictures}).then(response => {
            setContractLoadingApi(false);
            setContractSuccessApi(true);
        }).catch(error => {
            setContractLoadingApi(false);
            setContractErrorApi(true);
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(files.length && files.length > currentFile){
            const file = files[currentFile];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPictures(pictures.concat(reader.result));
                setCurrentFile(currentFile + 1);
            };
        }
    },[files, currentFile]);

    const uploadPictureRequest = (picture) => {
        setFiles(files.concat(picture));
    }

    return( <> 
     <LayoutCustomer >
            <Container fluid>
            {contractSuccessApi ? <AlertGeneral setTrigger={setContractSuccessApi} closeAlone description='Form inviato con successo' color='success' /> : null}
            {contractErrorApi ? <AlertGeneral setTrigger={setContractErrorApi} closeAlone description='Errore nel form' color='danger' /> : null}
                <Row style={{marginTop: '2rem'}}><Col className='text-center'><span className={classes.titleStyle}>Vendita auto</span></Col></Row>
                <Row><Col className='text-center'>
                    <span>Valutiamo la tua auto incidentata o non funzionante di recente immatricolazione</span>
                </Col></Row>
                <FormContact noSide uploadPicture 
                    onClickContactHandler={onClickContactHandler}
                    uploadPictureRequest = { uploadPictureRequest }/>
            </Container>
        </LayoutCustomer>
    </> )
}

export default DetailCar;