import React, {useState, useEffect} from 'react';
//----------- Bootstrap --------------
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ListCars from '../../Components/ListCar/ListCars';
//----------- Components ------------
import LayoutCustomer from '../../Hoc/LayoutCustomer';
//----------- other imports ------------
import classes from './CarListPage.module.css';
import * as clientCredentailApi from '../../Axios/Call/ClientCredential';

const CarListPage = () => {
    const [cars, setCars] = useState([]);
    const [text, setText] = useState('');
    const [searchedCars, setSearchedCars] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        clientCredentailApi.getCars().then(response => {
            setCars(response.data.data);
        }).catch(error => {
            console.log(error)
        })
    },[]);

    const searchCarsHandler = (text) => {
        const searchedCars = cars.filter(car => {
            return car.name.toLowerCase().search(text.toLowerCase()) !== -1;
        });
        setSearchedCars(searchedCars)
        setText(text);
    }

    const renderListCar = () => {
        let mapCars = cars;
        if(text && searchedCars.length){
            mapCars = searchedCars;
        }
        if(text && !searchedCars.length){
            return <span>Nessun risultato</span>
        }

        return mapCars.map(car => {
            return(
                <ListCars key={car.id} car={car}/>
            )
        })
    }
    return(<> 
        <LayoutCustomer >
            <Container fluid>
                <Row style={{marginTop: '2rem'}}>
                    <Col className={'text-center '+classes.TitleSection}><span>Ricambi</span></Col>
                </Row>
                <Row>
                    {/*<Col className='text-center'><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span></Col> */}
                </Row>
                <Row style={{marginTop: '2rem'}}><Col className='text-center'>
                    <span>Ricerca veicolo: </span>
                    <input value={text} onChange={(event) => searchCarsHandler(event.target.value)} />
                </Col></Row>
                {cars.length ? 
                    renderListCar()
                : null}
            </Container>
        </LayoutCustomer>
    </>)
}

export default CarListPage;