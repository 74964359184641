import { clientCredentialAxios } from '../AxiosInstance';


//--------------------------------- Main Page call ------------------------------------

export const  passwordGrantAuthentication= (data) => {
    return clientCredentialAxios.post('/oauth/token', data);
}

export const getCars = () => {
    return clientCredentialAxios.get('cars')
}

export const oneCar = (id) => {
    return clientCredentialAxios.get(`car/${id}`)
}

export const sendContact = (data) => {
    return clientCredentialAxios.post('contact',data);
}


