import React, {useState, useEffect} from 'react';
import * as authApi from '../../../Axios/Call/AuthApi';
import * as clientApi from '../../../Axios/Call/ClientCredential';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import ModalAddCar from './ModalAddCar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import {EditIcon, RemoveIcon, DoneIcon} from '../../../icons';

const CarListPage = () => {
    const [cars, setCars] = useState([]);
    const [carsError, setCarsError] = useState(false);
    const [loadingCars, setLoadingCars] = useState(false);
    const [deletePictureError, setDeletePictureError] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [carAdded, setCarAdded] = useState(false);

    useEffect(() => {
        setLoadingCars(true)
        authApi.getCars().then(response => {
            setCars(response.data.data);
            setLoadingCars(false);
        }).catch(error => {
            setCarsError(true);
            setLoadingCars(false);
        })
    },[carAdded]);

    const deletePicture = (carId, pictureId ) => {
        authApi.deletePicture(pictureId).then(response => {
            let newPicture = cars.find(car => car.id == carId).gallery;
            const index = newPicture.findIndex(pic => pic.id == pictureId);
            newPicture.splice(index,1);
            setCars(cars.map(car => car.id == carId ? {...car, gallery: newPicture } : car))
        }).catch(error => {
            setDeletePictureError(true);
        })
    }

    const onChangeStatusHandler = (carId) => {
        authApi.changeStatus(carId).then(response => {
            setCars(cars.map(car => car.id == carId ? {...car, delete: !car.delete} : car));
        }).catch(error => {
            setErrorStatus(true);
        })
    }

    return(<>
        {carsError ? <AlertGeneral closeAlone setTrigger={setCarsError} description='errore caricamento' color='danger' /> : null}
        {deletePictureError ? <AlertGeneral closeAlone setTrigger={setDeletePictureError} description='errore eliminazione foto' color='danger' /> : null}
        {errorStatus ? <AlertGeneral closeAlone setTrigger={setErrorStatus} description='errro cambio stato' color='danger' /> : null}
        {openModal ? <ModalAddCar setOpenModal={setOpenModal} setCarAdded={setCarAdded} carAdded={carAdded} openModal={openModal}/> : null}
        {loadingCars ? <LoadingButton /> :
        <>
            <Row style={{marginTop: '2rem'}}>
                <Col className='text-center'>
                    <span style={{fontWeight: 'bold', fontSize: '26px'}}>Ricambi</span>
                </Col>
            </Row>
            <Row><Col className='text-center'>
                <Button className='mainButton' onClick={() => setOpenModal(-1)}>
                    <span className='mainButtonText'>Nuova macchina</span>
                </Button>
            </Col></Row>
            <Row style={{marginTop: '2rem'}}>
                <Col>
                    {cars.map(car => {
                        return(
                            <Card style={{padding: 20, marginTop: '2rem'}}>
                                <Row>
                                    <Col xs={10}>
                                        <Row><Col><span style={{fontWeight: 'bolder'}}>Name:</span> <span>{car.name}</span></Col></Row>
                                        <Row><Col><span style={{fontWeight: 'bolder'}}>Modello:</span> <span>{car.model}</span></Col>
                                            <Col><span style={{fontWeight: 'bolder'}}>Anno:</span> <span>{car.year}</span></Col>
                                            <Col><span style={{fontWeight: 'bolder'}}>Brand:</span> <span>{car.brand}</span></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <EditIcon onClick={() => setOpenModal(car.id)} style={{width: 40}}/>
                                        { car.delete ?
                                            <DoneIcon onClick={() => onChangeStatusHandler(car.id)} style={{width: 40, fill: 'green'}}/>
                                            : 
                                            <RemoveIcon onClick={() => onChangeStatusHandler(car.id)} style={{width: 40, fill: 'red'}}/>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '1rem'}}>
                                    {car.gallery.map(picture => {
                                        return(
                                            <Col xs={3}>
                                                <Image src={picture.picture} style={{width: '60%'}} />
                                                <RemoveIcon onClick={() => deletePicture(car.id,picture.id)}style={{width: 40, fill: 'red', position:'absolute', top:0, left: 10}}/>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Card>
                        )
                    })}
                </Col>
            </Row>
        </>
        }
    </>)
}

export default CarListPage;