import React, {useState, useEffect} from 'react';
import * as authApi from '../../../Axios/Call/AuthApi';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';


const ContactListPage = () => {
    const [contacts, setContacts] = useState([]);
    const [errorApi, setErrorApi] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);

    useEffect(() => {
        setLoadingApi(true);
        authApi.getContacts().then(response => {
            setContacts(response.data.data);
            setLoadingApi(false);
        }).catch(error => {
            setErrorApi(true);
            setLoadingApi(false);
        })  
    },[]);

    return(<>
        {errorApi ? <AlertGeneral closeAlone setTrigger={setErrorApi} description='errore caricamento' color='danger' /> : null}
        {loadingApi ? <LoadingButton /> :
            <>
                <Row style={{marginTop: '2rem'}}>
                    <Col className='text-center'>
                        <span style={{fontSize: '20px', fontWeight: 'bold'}}>Contatti</span>
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem'}}>
                    <Col>
                        { contacts.map( contact => {
                            return(
                                <Row style={{marginTop: 10}}>
                                    <Col>
                                        <Card style={{padding: 30}}>
                                            <Row>
                                                <Col ><span style={{fontWeight: 'bolder'}}>Nome: </span><span>{contact.name} {contact.surname}</span></Col>
                                                <Col className='text-center'><span style={{fontWeight: 'bolder'}}>Email: </span><span>{contact.email}</span></Col>
                                                <Col className='text-right'><span style={{fontWeight: 'bolder'}}>Tipo: </span><span>{contact.type}</span></Col>
                                            </Row>
                                            <Row style={{marginTop: '2rem'}}><Col>
                                                <span style={{fontWeight: 'bolder'}}>Richiesta: </span><span>{contact.question}</span>
                                            </Col></Row>
                                            { contact.car_id ? 
                                                <Row style={{marginTop: '1rem'}}>
                                                    <Col>
                                                        <span style={{fontWeight: 'bolder'}}>Richiesta riferita alla macchina: </span>
                                                        <span>{contact.car.name}</span>
                                                    </Col>
                                                </Row>
                                            : null }
                                            { contact.gallery.length ? 
                                            <>
                                                <Row style={{marginTop: '1rem'}}>
                                                    <Col>
                                                        <span style={{fontWeight: 'bolder'}}>Immagini richiesta: </span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {contact.gallery.map(picture => {
                                                        return(
                                                            <Col xs={4}><Image src={picture.picture} style={{ width: '60%' }}/></Col>
                                                        )
                                                    })}
                                                </Row>
                                            </>
                                            :null}
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
            </>
        }
    </>)
}

export default ContactListPage;