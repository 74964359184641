import React, {useEffect} from 'react';
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './Layout.module.css';
import { useHistory } from 'react-router-dom';
import LoadingButton from '../Components/Commons/LoadingButton/LoadingButton';

const LayoutContentCreator = (props) => {
    const history = useHistory();
    const userData = useSelector(state => state.user.userData);

    return(
        <>
        <Container fluid>
            <Row style={{marginTop: '2rem'}}>
                <Col xs={12} md={6} className='text-center' style={{marginTop: '2rem'}}>
                    <Link to='/admin-contatti'>
                        <Button className='mainButton'>
                            <span className='mainButtonText'>Contatti</span>
                        </Button>
                    </Link>
                </Col>
                <Col xs={12} md={6} className='text-center' style={{marginTop: '2rem'}}>
                    <Link to='/admin-cars'>
                        <Button className='mainButton'>
                            <span className='mainButtonText'>Ricambi</span>
                        </Button>
                    </Link>
                </Col>
            </Row>
            { Object.keys(userData).length ?
                props.children
            : <LoadingButton />}
        </Container>
        </>
    )
}

export default LayoutContentCreator