import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import classes from './Login.module.css';
import { useForm } from 'react-hook-form';
import LoadingButton from '../../Components/Commons/LoadingButton/LoadingButton';
import {authApiAxios} from '../../Axios/AxiosInstance';
import * as clientCredentialApi from '../../Axios/Call/ClientCredential';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import * as actionCreator from '../../store/action/index';
import LayoutCustomer from '../../Hoc/LayoutCustomer';

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [loadingApi, setLoadingApi] = useState(false);
    const [errorApi, setErrorApi] = useState(false);

    useEffect(() => {
        if(Object.keys(userData).length){
                history.push('/');
        }
    },[userData])

    const emailValidator = (value) => value.trim().match(/^.+@.+$/) || 'Email non valida';
    const passwordValidator = (value) => value.trim().match(/^[^\s]{8,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';

    const { register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const onClickLoginHandler = (data) => {
        setLoadingApi(true);
        const apiData = {
            grant_type: 'password',
            client_id: 2,
            client_secret: 'wIG13Bdyz8BAMC4WK7ZI0NsdUhBwkwNtOSKKgBVF',
            username: data.email,
            password: data.password
        };
        clientCredentialApi.passwordGrantAuthentication(apiData).then((response) => {
                localStorage.setItem('token', 'Bearer ' + response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
                setLoadingApi(false);
                dispatch(actionCreator.storeUserData());
            })
            .catch((error) => {
                setLoadingApi(false);
                setErrorApi(true);
            });
    }


    return(
        <>
        <LayoutCustomer >
        <Card style={{marginTop: '4rem', marginBottom:'8rem'}}>
            <Row style={{padding: 20}}>
                <Col>
                    <Row>
                        <Col><span style={{color:'white',
                                           letterSpacing:'2px'}} className='titleSection'>Accedi</span></Col>
                    </Row>
                    <Form style={{ marginTop: '2rem'}} onSubmit={handleSubmit(onClickLoginHandler)}>
                        <Row>
                            <Col xs={12} >
                                <Form.Label  className={classes.LabelRegistrationForm} >Indirizzo Email</Form.Label>
                                <Form.Control   type="email" name='email'
                                    className={classes.InputRegistrationForm}
                                    ref={register({ validate: emailValidator })}
                                    placeholder = 'Inserisci la tua email'
                                />
                                {errors.email && ( <small className="text-danger small">{errors.email.message}</small>)}
                        </Col>
                        <Col>
                                <Form.Label className={classes.LabelRegistrationForm}>Password</Form.Label>
                                <Form.Control type="password" name='password'
                                    className={classes.InputRegistrationForm}
                                    placeholder='Inserisci Password'
                                    ref={register({ validate: passwordValidator })}
                                />
                                {errors.password && ( <small className="text-danger small">{errors.password.message}</small>)}   
                        </Col>
                        </Row>
                        <Row>
                            <Col>{errorApi && ( <small className="text-danger small">La tua email o password sono errate</small>)}</Col>
                        </Row>
                        <Row style={{marginTop: '2rem'}}>
                            <Col>
                                { loadingApi ? <LoadingButton /> :
                                    <Button className='mainButton' type='submit'>
                                        <span  className='mainButtonText'>Accedi</span>               
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Card>
        </LayoutCustomer>
        </>
    )
}

export default Login;