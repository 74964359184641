import React, { useEffect } from "react";
//----------- Bootstrap --------------
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
//----------- Components ------------
import LayoutCustomer from "../../Hoc/LayoutCustomer";
import Company from "../../Assets/Images/azienda.jpg";
import Company2 from "../../Assets/Images/azienda2.jpg";
import Sociale from "../../Assets/Images/sociale.jpg";
//----------- other imports ------------
import classes from "./AboutUs.module.css";
import LogoPdf from "../../Assets/Images/logo-pdf.gif";
import Doc from "../../Assets/Images/POL.pdf";
import CodiceEtico from "../../Assets/Images/Grillofer-Codice-Etico.pdf";
import MOGC from "../../Assets/Images/MOGC.pdf";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <LayoutCustomer>
        <Container fluid>
          <Row style={{ marginTop: "2rem" }}>
            <Col className="text-center">
              <span className={classes.TitleSection}>Su di noi</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <span>Scopri la Grillofer srl e le sue politiche</span>
            </Col>
          </Row>
          <Row style={{ marginTop: "2rem" }}>
            <Col xs={12} md={8} className="my-auto">
              <Row>
                <Col>
                  <span className={classes.Title}>Missione Aziendale</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <p>
                    La GRILLOFER srl, operante nel campo del trattamento di
                    veicoli fuori uso e nelle operazioni di recupero, è
                    pienamente consapevole che una responsabile strategia
                    economica, rivolta alle problematiche ambientali derivanti
                    dalle proprietà, risulta essere essenziale per il proprio
                    successo e per quello dei propri Clienti.
                  </p>
                  <p>
                    La GRILLOFER srl si impegna, pertanto, a perseguire una
                    Politica di continuo miglioramento delle proprie performance
                    ambientali, minimizzando, ove tecnicamente possibile ed
                    economicamente sostenibile, ogni impatti negativo, verso
                    l’ambiente, delle proprie attività.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Image src={Company} style={{ width: "100%" }} />
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col xs={12} md={4}>
              <Image src={Company2} style={{ width: "100%" }} />
            </Col>
            <Col>
              <Row>
                <Col>
                  <span className={classes.Title}>Responsabilita' sociale</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <span>
                    In un mondo che sta evolvendosi verso la totale
                    sostenibilità delle operazioni umane, il primo passo da fare
                    è assumersi le proprie responsabilità, sia come Aziende che
                    come singoli individui, ed è per questo che la Grillofer
                    S.N.C. nei suoi processi aziendali ed in tutte le operazioni
                    che la riguardano, si impegna nell'assicurare il massimo
                    rispetto e considerazione di tutti i suoi Stake-Holders,
                    partendo da coloro che lavorano nel nostro centro - ai
                    nostri fornitori; da i nostri clienti - a tutta la comunità
                    Ternana
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col xs={12} md={9}>
              <Row>
                <Col>
                  <span className={classes.Title}>Politica ambientale</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <p>
                    La GRILLOFER srl, operante nel campo del trattamento di
                    veicoli fuori uso e nelle operazioni di recupero, è
                    pienamente consapevole che una responsabile strategia
                    economica, rivolta alle problematiche ambientali derivanti
                    dalle proprietà, risulta essere essenziale per il proprio
                    successo e per quello dei propri Clienti.
                  </p>
                  <p>
                    La GRILLOFER srl riconosce, inoltre, che il miglioramento
                    continuo delle proprie performance ambientali, conduce a
                    significativi vantaggi commerciali ed economici,
                    soddisfacendo, nello stesso tempo, le attese di
                    miglioramento ambientale relativo al contesto territoriale
                    in cui essa opera.
                  </p>
                  <p>
                    La GRILLOFER srl si impegna, pertanto, a perseguire una
                    Politica di continuo miglioramento delle proprie performance
                    ambientali, minimizzando, ove tecnicamente possibile ed
                    economicamente sostenibile, ogni impatti negativo, verso
                    l’ambiente, delle proprie attività.
                  </p>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <span className={classes.Title}>File Utili</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>
                  puoi trovare la nostra politica aziendale completa qui
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href={Doc} target="_blank">
                    <Image style={{ width: 100 }} src={LogoPdf} />
                  </a>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <span className={classes.Title}>Codice Etico</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href={CodiceEtico} target="_blank">
                    <Image style={{ width: 100 }} src={LogoPdf} />
                  </a>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <span className={classes.Title}>MOGC 231</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href={MOGC} target="_blank">
                    <Image style={{ width: 100 }} src={LogoPdf} />
                  </a>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <span className={classes.Title}>Organismo di Vigilanza</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={classes.divBorder} />
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col>email: odvgrillofersegnalazioni@yahoo.com</Col>
              </Row>
            </Col>
            <Col>
              <Image src={Sociale} style={{ width: "100%" }} />
            </Col>
          </Row>
        </Container>
      </LayoutCustomer>
    </>
  );
};

export default AboutUs;
